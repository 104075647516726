import React from "react"
import { LayoutWhite } from "@layout"
import { HeroHomepage2022Q3 } from "@components/hero"
import SEO from "@components/seo"
import {
  MainBlockTitleUnderline,
  MainBlockJoinColony,
  MainBlockTestimonials,
  MainBlockWealthBoost,
  MainBlockAppVideos,
  MainBlockScrollingBrands,
} from "@components/main-blocks"

const iOS = () => (
  <LayoutWhite
    backgroundImage="animated"
    hero={<HeroHomepage2022Q3 platform="ios" />}
  >
    <SEO
      overrideTitleTemplate={`%s`}
      title="Earn Money with ATM.com - Earn Cash, Save, and Invest With The ATM App"
      description="Earn money with the ATM.com® app matching with top brands! Our unique Brand Matching feature gives you the ability to earn extra cash using your phone!"
      canonical={`https://www.atm.com/`}
    />
    <MainBlockTitleUnderline />
    <MainBlockAppVideos />
    <MainBlockScrollingBrands />
    <MainBlockWealthBoost />
    <MainBlockTestimonials />
    <MainBlockJoinColony platform="ios" />
  </LayoutWhite>
)

export default iOS
